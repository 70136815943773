<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model
            :colon="false"
            :model="form"
            layout="inline"
            @keyup.enter.native="query"
          >
            <a-form-model-item>
              <a-input
                v-model="form.title"
                placeholder="标题"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button
              v-if="$getPermission($route.path + '/add')"
              @click="$router.push($route.path + '/add')"
              type="primary"
              >创建</a-button
            >
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        rowKey="id"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="标题">
          <template slot-scope="text">
            <a href="#" @click.prevent="openDetail(text)" title="评分结果">
              {{ text.title }}
            </a>
          </template>
        </a-table-column>

        <a-table-column title="有效时间范围" align="center">
          <template slot-scope="text">
            {{ text.startTime }} ~ {{ text.endTime }}
          </template>
        </a-table-column>
        <a-table-column title="状态" align="center">
          <template slot-scope="text">
            <Status :startTime="text.startTime" :endTime="text.endTime" />
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center" width="280px">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                v-if="$getPermission($route.path + '/detail')"
                @click.prevent="openDetail(text)"
                >评分结果</a
              >
              <a
                href="#"
                v-if="$getPermission($route.path + '/edit')"
                @click.prevent="downloadQRCode(text)"
                >评委邀请码</a
              >
              <a
                href="#"
                v-if="$getPermission($route.path + '/edit')"
                @click.prevent="downloadAnonymousQRCode(text)"
                >邀请码</a
              >
              <a
                href="#"
                v-if="$getPermission($route.path + '/edit')"
                @click.prevent="edit(text)"
                >编辑</a
              >
              <a
                href="#"
                v-if="$getPermission($route.path + '/delete')"
                @click.prevent="deleteText(text)"
                class="danger"
                >删除</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>

    <a-modal
      title="选择评委"
      :visible="qrCodeVisible"
      @cancel="cancel"
      :footer="null"
    >
      <a-table
        bordered
        :data-source="Array.isArray(detail.judgesList) ? detail.judgesList : []"
        :pagination="false"
        :row-selection="{
          selectedRowKeys,
          onChange: onSelectChange,
        }"
        rowKey="userId"
      >
        <a-table-column title="部门" data-index="deptName" />
        <a-table-column title="姓名" data-index="userName" />
      </a-table>

      <div class="right" style="padding-top: 16px">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button type="primary" @click="selectSure">下载</a-button>
        </a-space>
      </div>
    </a-modal>
  </div>
</template>


<script>
import Status from "./components/status.vue";
import watermark from "@/mixins/watermark";
import { fetchList, fetchDetail, remove } from "./api";
import { mapGetters } from "vuex";
import { saveAs } from "file-saver";
export default {
  mixins: [watermark],
  components: {
    Status,
  },

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      qrCodeVisible: false,
      detail: {},
      selectedRowKeys: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    partyWorkType() {
      return this.findDataDict("partyWorkType");
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;

      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    openDetail(text) {
      this.$router.push(this.$route.path + "/detail?id=" + text.id);
    },
    edit(text) {
      this.$router.push(this.$route.path + "/edit?id=" + text.id);
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    downloadQRCode(text) {
      const hide = this.$message.loading("加载中...");
      fetchDetail({
        id: text.id,
      })
        .then((res) => {
          this.detail = res ?? {};
          this.qrCodeVisible = true;
        })
        .finally(() => {
          hide();
        });
    },
    cancel() {
      this.qrCodeVisible = false;
      this.detail = {};
      this.selectedRowKeys = [];
    },
    onSelectChange(keys) {
      this.selectedRowKeys = keys;
    },
    selectSure() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error("请选择评委");
        return;
      }

      const users = [];
      this.selectedRowKeys.forEach((key) => {
        const user = this.detail?.judgesList.find(
          (item) => item.userId === key
        );
        if (user) {
          users.push(user);
        }
      });

      users.forEach((item) => {
        const url = `${window.location.origin}/technical-quality.html?id=${this.detail.id}&userId=${item.userId}&userName=${item.userName}`;
        this.getQRCodeByDownload({
          url,
          userName: item.userName,
          callback: this.download,
        });
      });
    },

    downloadAnonymousQRCode(text) {
      const url = `${window.location.origin}/technical-quality-login.html?id=${text.id}`;
      this.getQRCodeByDownload({
        url,
        userName: "评分",
        callback: this.download,
      });
    },

    getQRCodeByDownload({
      url = "",
      userName = "",
      color = "#000000",
      callback,
    }) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = 160;
      canvas.height = 160;
      context.fillStyle = "#1890ff";
      context.fillRect(0, 0, 160, 160);
      context.fillStyle = "#ffffff";
      context.font = "50px sans-serif";
      context.fillText(userName, userName.length > 2 ? 5 : 30, 100);
      const image = canvas.toDataURL("image/png");

      new window.AwesomeQR.AwesomeQR({
        text: url,
        size: 320,
        margin: 0,
        logoImage: image,
        logoScale: 0.2,
        logoCornerRadius: 0,
        colorDark: color,
      })
        .draw()
        .then((dataURL) => {
          callback({
            dataURL,
            userName,
          });
        });
    },

    download({ dataURL, userName }) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = 320;
      canvas.height = 320;

      const image = new Image();
      image.onload = function () {
        context.drawImage(image, 0, 0);

        canvas.toBlob(function (blob) {
          saveAs(blob, `${userName}.png`);
        });
      };
      image.src = dataURL;
    },
  },
};
</script>