<template>
  <span>
    <a-badge status="default" text="已结束" v-if="status === 'done'" />
    <a-badge status="processing" text="进行中" v-else-if="status === 'ing'" />
    <a-badge
      status="warning"
      text="未开始"
      v-else-if="status === 'not-start'"
    />
    <span v-else></span>
  </span>
</template>

<script>
import moment from "moment";
export default {
  props: {
    startTime: {
      type: String,
    },
    endTime: {
      type: String,
    },
  },
  computed: {
    status() {
      if (this.startTime && this.endTime) {
        const now = moment();
        const start = moment(this.startTime);
        const end = moment(this.endTime);
        if (now.isBefore(start)) {
          return "not-start";
        } else if (now.isAfter(start) && now.isBefore(end)) {
          return "ing";
        } else if (now.isAfter(end)) {
          return "done";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },
};
</script>